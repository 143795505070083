import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

const IndexPage = () => {
  return (
    <Layout pageTitle="Home">
      <p>Other Architecture, Other Environment.　遠い建築、遠い環境</p>
      <StaticImage
        alt="Coded Environment"
        src="../image/header_1500_500.png"
      />
      <h2>About</h2>
      <p>建築環境工学に関する計算とコードについて。計算速度を要求するものにはRust🦀を、それ以外やデータ分析はPython🐍を使用する事を目安としていますが厳密ではありません。記載内容の誤りや不備については以下の連絡先へお問い合わせください。</p>
      <p>codedenvironment(あっとまーく)gmail.com</p>
    </Layout>
  )
}
export default IndexPage